import React from 'react';
import { Button, EmptyLayout, Paragraph as P } from '@periodica/ui-kit';
import { Link } from 'gatsby';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import Layout from '../../components/Layouts/Layout';
import { OneColumn } from '../../components/Layouts/OneColumn';
import SEO from '../../components/Layouts/SEO';

export function Head() {
  return (
    <>
      <SEO
        title="Заказ подтвержден"
        description="Ваш заказ подтвержден и готов к печати. Мы сообщим вам как только заказ будет передан в
    печать/доставку."
      />
      <YandexTableau />
    </>
  );
}

function OrderApprovedPage() {
  return (
    <Layout>
      <OneColumn>
        <EmptyLayout
          title="Заказ подтвержден!"
          description={
            <P size="inherit">
              Мы взяли ваш заказ в&nbsp;работу. Мы отправляем заказы в&nbsp;печать в&nbsp;10:00
              каждый будний&nbsp;день. Как&nbsp;только заказ будет передан в&nbsp;печать, мы сообщим
              вам&nbsp;отдельным письмом.
            </P>
          }
        >
          <Button size="xLarge" variant="secondary" asChild>
            <Link to="/" className="order-success__button">
              Хорошо
            </Link>
          </Button>
        </EmptyLayout>
      </OneColumn>
    </Layout>
  );
}

export default OrderApprovedPage;
